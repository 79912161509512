import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    isOpen: { type: Boolean, default: false },
  };

  connect() {
    this.handleClickOutside = this.handleClickOutside.bind(this);
    document.addEventListener("click", this.handleClickOutside);
  }

  disconnect() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      !this.element.contains(event.target) &&
      !this.element.classList.contains("hidden")
    ) {
      if (this.isOpenValue) {
        this.element.classList.add("hidden");
        this.isOpenValue = false;
      } else {
        this.isOpenValue = true;
      }
    }
  }
}
