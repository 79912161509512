import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.launchConfetti();
  }

  launchConfetti() {
    // Llama al efecto de confeti
    const duration = 1 * 1000; // Duración en milisegundos
    const end = Date.now() + duration;

    (function frame() {
      confetti({
        particleCount: 5,
        angle: 90,
        spread: 150,
        origin: { x: 0.5 },
        colors: ["#54CD9C", "#CCF0E1", "#28536B", "#67A4C6"],
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();
  }
}
