import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["label"]; // Define el target para actualizar el contenido

  connect() {
    this.updateTimeAgo();
    this.interval = setInterval(() => this.updateTimeAgo(), 60000); // Actualiza cada minuto
  }

  disconnect() {
    clearInterval(this.interval); // Limpia el intervalo al desconectar el controlador
  }

  updateTimeAgo() {
    let dateString = this.element.dataset.time; // Obtén la fecha desde un atributo `data-time`
    let result = this.timeAgo(dateString);
    this.labelTarget.textContent = result;
  }

  timeAgo(dateString) {
    let inputDate = new Date(dateString);
    let now = new Date();
    let diffMs = now - inputDate;

    if (isNaN(diffMs)) {
      return "Fecha inválida";
    }

    let diffMinutes = Math.floor(diffMs / (1000 * 60));
    let diffHours = Math.floor(diffMinutes / 60);
    let diffDays = Math.floor(diffHours / 24);

    if (diffDays > 0) {
      return `hace ${diffDays} días`;
    } else if (diffHours > 0) {
      return `hace ${diffHours} horas`;
    } else if (diffMinutes > 1) {
      return `hace ${diffMinutes} minutos`;
    } else if (diffMinutes === 1) {
      return `hace ${diffMinutes} minuto`;
    } else {
      return `hace menos de un minuto`;
    }
  }
}
