import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submit", "loading", "saved", "error", "time", "timer"];
  static values = {
    timer: { type: Number, default: 60000 },
    autosave: { type: Boolean, default: true },
  };

  connect() {
    this.refreshTimer = setInterval(() => {
      this.clickSubmit(this.submitTarget);
    }, this.timerValue);

    this.submitTarget.disabled = false;
  }

  disconnect() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
    clearInterval(this.interval); // Limpia el intervalo al desconectar el controlador
  }

  clickSubmit(element) {
    if (this.autosaveValue) {
      element.click();
    } else {
      console.log("Autosave disabled");
    }
  }

  log() {
    let uid = this.generateRandomString();
    this.loadingTarget.classList.remove("hidden");
    this.loadingTarget.classList.add(`waiting-${uid}`);
    this.savedTarget.classList.add("hidden");

    setTimeout(() => {
      if (this.loadingTarget.classList.contains(`waiting-${uid}`)) {
        this.autosaveValue = false;
        this.loadingTarget.classList.add("hidden");
        this.errorTarget.classList.remove("hidden");
      }
    }, 5000);
  }

  save() {
    this.log();
    this.submitTarget.click();
  }

  generateRandomString() {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    const length = 8;
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }
}
